<template>
<!--
    <div id="open__menu__container" :class="[!footerPosition ? 'open__menu__container' : 'open__menu__container__footer']">
-->


        <ul
            v-if="this.menuForServices.length"
            :class="[!footerPosition ? 'open__menu__ul' : 'open__menu__ul__footer']"
        >
            <li
                @click="$emit('closeServiceMenu')" v-for="(item, index) in  this.menuForServices[4].items" :key="item.id"
                class="link__services__menu"
                :class="[{'link__services__menu__active': $route.params.id === item.slug}]"
                :style="!isFooter ? { marginLeft: `${index * 1.5}vw` } : null "
            >
                <router-link  :to="`/services/${item.slug}`" @ckick="getLinkBySlug(item.slug)">
                    {{ item.title }}
                </router-link>
            </li>

        </ul>


<!--
    </div>
-->
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        isOpenServicesMenu: {
            type: Boolean,
            require: true
        },
        footerPosition: {
            type: Boolean,
            require: false
        },
      isFooter: {
        type: Boolean,
        require: false
      }
    },
    name: "OpenServicesMenu",
    data() {
        return {
            isActive: false,
            menu: [],
            servicesLinkBySlug: '',

        }
    },
    computed: {
        ...mapGetters([
            'menuLinkText',
            'menuForServices'
        ]),

    },


    mounted() {
        /*this.$store.dispatch('getMenuServices').then(() => {
            console.log(this.menuForServices[4].items.find(item => item.slug === this.$route.params.id), '1111')
        });*/
    },
    methods: {
        getLinkBySlug(item) {
            this.servicesLinkBySlug = item
            console.log(this.servicesLinkBySlug, 'servicesLinkBySlug')
        },
    }
}
</script>

<style scoped lang="scss">
.nav__container__active{
    font-size: 0.938vw;
    text-transform: uppercase;
    transition: 0.5s;
    color: red;
}
.link__services__menu {
  white-space: pre-wrap;
  cursor: pointer;
}

.link__services__menu a {
  cursor: pointer;
  transition: 0.5s;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 0.938vw;
  text-transform: uppercase;
}
.link__services__menu__active a {
    cursor: pointer;
    transition: 0.5s;
    text-decoration: none;
    font-size: 0.938vw;
    color: #FFFFFF;
    opacity: 0.5;
}

.link__services__menu a:hover {
  color: #FFFFFF;
  opacity: 0.5;
}

@keyframes showBackground {
  from {
    background-color: rgba(33, 33, 33, 0);
  }

  to {
    background-color: rgba(33, 33, 33, 100);
  }
}

.open__menu__container__footer {
  bottom: 11vw;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  right: 19vw;
  z-index: 9;
  padding-top: 3vw;
  width: 35.156vw;
  height: 35.156vw;
    -webkit-clip-path: polygon(50% 0%, 0 100%, 100% 100%);
    clip-path: polygon(50% 0%, 0 100%, 100% 100%);
    background: linear-gradient(180.07deg, rgba(0, 0, 0, 0.4) 0.06%, rgba(0, 0, 0, 0.8) 74.74%);

}
.open__menu__container{
  position: fixed;
  top: 0;
  right: 26.6vw;
  z-index: 9;
  padding-top: 4vw;
}

.open__menu__list {
  transform: rotate(360deg);
  text-align: right;
  display: flex;
  width: 5.733vw;
  height: 5.200vw;

  position: absolute;
  top: 4.867vw;
  right: 24.933vw;
}

.open__menu__ul {
  position: fixed;
  top: 4vw;
  right: 1.5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30vw;
  height: max-content;
  line-height: 2.5vw;
  text-align: left;
}

.open__menu__ul__footer {
  z-index: 9;
  position: absolute;
  right: 1.3vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  bottom: 13vw;
  width: 30vw;
  height: max-content;
  line-height: 2.5vw;
  text-align: left;

}

.open__menu__item {
  text-transform: uppercase;

  list-style-type: none;
  transform: translateY(-40px);
  opacity: 0;
  transition: all .2s ease;
}

.open__menu__nuxt__link {
  font-size: 0.3vw;
  text-decoration: none;
  color: rgba(246, 242, 242, 1);
  font-family: 'Benzin-Regular';
  cursor: pointer;
  transition: 0.3s;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

.open__menu__nuxt__link:hover {
  color: rgba(219, 16, 35, 1);
}

.open__menu__nuxt__link_active {
  font-size: 3.733vw;
  text-decoration: none;
  font-family: 'Benzin-Regular';
  color: rgba(219, 16, 35, 1);
  cursor: pointer;
  transition: 0.3s;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;

}

.open__menu__container__social {
  position: absolute;
  bottom: 0;
  transform: rotate(315deg);
  width: 24.533vw;
  bottom: 15.200vw;
  right: 17.067vw;
}

.open__menu__container__social__img {
  width: 5.600vw;
  height: 5.600vw;
}

.open__menu__container__social__img__facebbok {
  width: 2.667vw;
  height: 5.600vw;
}


.open__menu__container__social__NuxtLinks__instagramm {
  margin-right: 5.333vw;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

.open__menu__container__social__NuxtLinks__facebook {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

.open__menu__container__social__NuxtLinks__linkedin {
  margin-right: 5.333vw;

  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

</style>